import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Text, Box, Flex, Spacer } from '@chakra-ui/react'

const Header = ({ siteTitle }) => (
  <Box background="gray.100">
  <header>
    <Flex
      style={{
        margin: `0 auto`,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <Box>
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `Black`,
            textDecoration: `none`,
          }}
        >
          <Text fontWeight="900">
          {siteTitle}
          </Text>
        </Link>
      </h1>
      </Box>
      <Spacer />
      <Box>
      <Link
          to="/"
          style={{
            color: `Black`,
            textDecoration: `none`,
          }}
        >
          <Text fontWeight="900">
          Home
          </Text>
        </Link>
      </Box>
    </Flex>
  </header>
  </Box>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
